import * as React from "react";
import Redirect from "../../../../components/redirect";
//--------------------------------------------------

  // hong kong edition
  const url = "https://www.chinadailyhk.com/article/238886#The-art-of-community-bonding";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;

